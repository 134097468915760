$white: #fff;

.white {
    color: $white;
}

.bg-tl{
    background-color: #36489b !important;
    color: #ffffff;
}
.header {
    height: 56px;
    margin-bottom: 8px;
}
.footer{
    text-align: center;
    padding-top: 60px;
    position: relative;
    bottom: 12px;
}
.header nav {    
    box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 0px 2px 2px rgba(0, 0, 0, 0.098), 0px 0px 5px 1px rgba(0, 0, 0, 0.084);    
}
.menu-handle {    
    background-color: transparent;
    border: none;
    width: 30px;
    height: 24px;
    padding: 0;
    outline: none;        
    right: 70px;
    z-index: 2000;
    cursor: pointer;
}

.go-top {
    position: fixed;
    bottom: 10px;
    right: 20px;
}

.luong-form > h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
}

.social-login a {
    animation: 1s ease-in-out 0s normal none infinite running suntory-alo-circle-img-anim;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    line-height: 46px;
    padding: 0;
    margin: 0 1px;
    border-width: 0;
}

.suntory-alo-phone.suntory-alo-green .suntory-alo-ph-img-circle {
    background-color: #00aff2;
}
.suntory-alo-ph-img-circle {
    animation: 1s ease-in-out 0s normal none infinite running suntory-alo-circle-img-anim;
    border: 2px solid transparent;
    border-radius: 100%;
    height: 50px;
    left: 25px;
    opacity: 0.7;
    top: 25px;
    transform-origin: 50% 50% 0;
    width: 50px;
}
.suntory-alo-ph-circle-fill {
    animation: 2.3s ease-in-out 0s normal none infinite running suntory-alo-circle-fill-anim;
    border: 2px solid transparent;
    border-radius: 100%;
    height: 70px;
    left: 15px;
    top: 15px;
    transform-origin: 50% 50% 0;
    transition: all 0.5s ease 0s;
    width: 70px;
}
@keyframes suntory-alo-circle-img-anim {
    0% {
      transform: rotate(0deg) scale(1) skew(1deg);
    }
    10% {
      transform: rotate(-25deg) scale(1) skew(1deg);
    }
    20% {
      transform: rotate(25deg) scale(1) skew(1deg);
    }
    30% {
      transform: rotate(-25deg) scale(1) skew(1deg);
    }
    40% {
      transform: rotate(25deg) scale(1) skew(1deg);
    }
    50% {
      transform: rotate(0deg) scale(1) skew(1deg);
    }
    100% {
      transform: rotate(0deg) scale(1) skew(1deg);
    }
  }
  @keyframes suntory-alo-circle-fill-anim {
    0% {
      opacity: 0.2;
      transform: rotate(0deg) scale(0.7) skew(1deg);
    }
    50% {
      opacity: 0.2;
      transform: rotate(0deg) scale(1) skew(1deg);
    }
    100% {
      opacity: 0.2;
      transform: rotate(0deg) scale(0.7) skew(1deg);
    }
  }

  .invalid-tooltip > ul{
    margin-bottom: 0;
    font-size: 9px;
  }

  @keyframes blinking {
    0%{
      color: #36489b;
    }
    /* YOU CAN ADD MORE COLORS IN THE KEYFRAMES IF YOU WANT 
    50%{
      color: #55d66b;
    }
    */
    100%{
      color: #fff;
    }
  }
  .navbar-brand{
    /* NAME | TIME | ITERATION */
    animation: blinking 10s infinite;
  }

  .hot-nav-link{
    color: yellow !important;
  }