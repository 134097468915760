//
// tinhluong Color system
//
$main-color: #552200;
//my colors
$walnut-brown: #5c5248;
$sepia-brown: #7a7a0b;
$emerald-green: #50c878;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #eee;
$gray-300: #dee2e6;
$gray-400: #ccc;
$gray-500: #adb5bd;
$gray-600: #888;
$gray-700: #495057;
$gray-800: #333;
$gray-900: #222;
$black:    #000;

$blue:    #293956;
$indigo:  #36489b;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #F04124;
$orange:  #f2a32c;
$yellow:  #E99002;
$green:   $emerald-green;
$teal:    #20c997;
$cyan:    #4b6daa;

$primary:       $blue;
$secondary:     $gray-200;
$success:       $green;
$info:          $indigo;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-200;
$dark:          $gray-900;

$yiq-contrasted-threshold: 200;

// Components

$border-radius:               0;
$border-radius-lg:            0;
$border-radius-sm:            0;

// Fonts

$font-family-sans-serif:      "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$font-size-base:              0.9375rem;

$headings-font-weight:        500;

// Buttons

$input-btn-padding-x:         0.5rem;

$btn-font-weight:             300;

// Dropdowns

$dropdown-border-color:             rgba($black,.1);
$dropdown-divider-bg:               rgba($black,.1);

// Navs

$nav-link-disabled-color:           $gray-400;

$nav-tabs-border-color:             $dropdown-border-color;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color;
$nav-tabs-link-active-border-color: $nav-tabs-border-color;

// Navbar

$navbar-dark-color:                 rgba($white,.7);
$navbar-dark-hover-color:           $white;

// Pagination

$pagination-color:                  $gray-600;
$pagination-border-color:           $nav-tabs-border-color;

$pagination-active-border-color:    darken($primary, 5%);

$pagination-disabled-color:         $gray-200;

// Jumbotron

$jumbotron-padding:                 4rem;

// Badges

$badge-font-weight:                 300;
$badge-padding-x:                   1rem;

// Progress bars

$progress-bg:                       $gray-400 !important;
$progress-bar-color:                $white;

// List group

$list-group-disabled-bg:            $gray-200;

// Close

$close-color:                       $gray-600;
$close-text-shadow:                 none;

//custom
$colors: 
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  
); 
$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark 
); 

$tooltip-font-size:  9px;